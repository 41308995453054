import React, { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import { Button } from "@mui/material";
import { updateBusiness, uploadImage } from "../../Services/Api/Api";
import { ImageUploader } from "../../Services/Utils/Utils";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../Services/Store/userSlice";

const EditListing = ({ baseUrl, data, getListingData, setListingModal }) => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(baseUrl + data?.img);
  const { TextArea } = Input;
  const dispatch = useDispatch()

  useEffect(() => {
    form.setFieldsValue({ image: data?.img });
  }, [data]);

  const [imageResponse, setImageresponse] = useState("");
  const [apiCalled, setApiCalled] = useState(false);

  // Handle Image Change
  const handleImageChange = async (fileObj, base64) => {
    setImageUrl(base64);

    setApiCalled(true); // Set the state to true to indicate that the API is being called
    const result = await uploadImage(fileObj);
    const { data, status, error } = result || {};

    if (status === 200) {
      setImageresponse(data?.img);
      form.setFieldsValue({ image: data?.img });
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  const onFinish = async (values) => {
    const result = await updateBusiness(values);
    const { data, status, error } = result || {};
    if (status === 200) {
      form.resetFields();
      setListingModal(false)
      dispatch(updateUserData({img: values.image, company_name: values.company}))
      notification.success({ message: data?.message });
      getListingData()
    } else {
      notification.error({ message: error?.message });
    }
  };

  // Initial Values
  const initialValues = {
    company: data?.company_name,
    web: data?.website,
    details: data?.description,
  };

  return (
    <div>
      <Form
        className="edit-profile-form"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <div className="image-uploader">
          <Form.Item
            name="image"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <ImageUploader
              form={form}
              imageUrl={imageUrl}
              handleChange={handleImageChange}
            />
          </Form.Item>
        </div>

        <div className="name">
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              { min: 3, message: "Should be 3 characters." },
              {
                max: 30,
                message: "Can not be more than 30 characters.",
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>
        </div>

        <div className="web">
          <Form.Item
            name="web"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              {
                type: 'url',
                message: "Invalid Url",
              },
            ]}
          >
            <Input placeholder="Website" size="large" />
          </Form.Item>
        </div>

        <div className="details">
          <Form.Item
            name="details"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              {
                max: 30,
                message: "Can not be more than 100 characters.",
              },
            ]}
          >
            <TextArea placeholder="Details" size="large" rows={5} />
          </Form.Item>
        </div>

        <div className="actions">
          <Button type="submit" variant="contained" className="main-button">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditListing;