import React, { useEffect, useState } from "react";
import { PageHeader } from "../../Components";
import "./AboutUs.css";
import { getContent } from "../../Services/Api/Api";
import { notification } from "antd";

const AboutUs = () => {
  const [data, setData] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [missionData, setMissionData] = useState({});
  const [visionData, setVisionData] = useState({});

  const getData = async () => {
    const result = await getContent("about-us");
    const { data, status, error } = result;

    if (status === 200) {
      setData(data?.cms);
      setBaseUrl(data?.base_url);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  const getMissionVision = async () => {
    const result = await getContent("our-mission");
    const { data, status, error } = result;

    if (status === 200) {
      setMissionData(data?.cms?.points[0]);
      setVisionData(data?.cms?.points[1]);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  useEffect(() => {
    getData();
    getMissionVision();
  }, []);

  return (
    <div className="about-us">
      <PageHeader title="About Us" />
      <div className="inner-container">
        <div className="row">
          <div className="col-md-6">
            <img className="about-img" src={baseUrl + data?.img} alt="about-image" />
          </div>
          <div className="col-md-6">
            <div className="col-text">
              <h3>{data?.title}</h3>
              <p>{data?.description}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mission">
              <img src="/assets/icons/mission.png" className="icon" />
              <h4>{missionData?.title}</h4>
              <p>{missionData?.description}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="vision">
              <img src="/assets/icons/vision.png" className="icon" />
              <h4>{visionData?.title}</h4>
              <p>{visionData?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;