import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Button } from "@mui/material";
import { Modal, Table, notification } from "antd";
import { Link } from "react-router-dom";
import EditListing from "./EditListing";
import PromoteForm from "./PromoteForm";
import {
  getBusiness,
  getPromotions,
  getPromotionsPlans,
} from "../../Services/Api/Api";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [promotionData, setPromotionData] = useState([]);
  const [promotionPlans, setPromotionPlans] = useState([]);
  const [listingData, setListingData] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  
  const userDetails = useSelector((state) => state.user.value);
  const userBaseUrl = useSelector((state) => state.user.baseUrl);
  const [overviewData, setOverviewData] = useState({});

  useEffect(() => {
    setOverviewData(userDetails);
    setBaseUrl(userBaseUrl);
  }, [userDetails]);

  const getPromotionData = async () => {
    const result = await getPromotions();
    const { data, status, error } = result || {};

    if (status === 200) {
      setPromotionData(data?.promotions);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  const getPromotionPlansData = async () => {
    const result = await getPromotionsPlans();
    const { data, status, error } = result || {};

    if (status === 200) {
      setPromotionPlans(data?.promotions);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  const getLisitingData = async () => {
    const result = await getBusiness();
    const { data, status, error } = result || {};

    if (status === 200) {
      setListingData(data?.business);
      setBaseUrl(data?.base_url);
    } else {
      notification.error({ message: error?.message?.message || "Internal server error" });
    }
  };

  useEffect(() => {
    getPromotionData();
    getLisitingData();
    getPromotionPlansData();
  }, []);

  const columns = [
    {
      title: "Promotion Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Promotion Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (type === 1 ? "Top of the List" : "Banner"),
    },
    {
      title: "Start Date",
      dataIndex: "t",
      key: "start",
      render: (t, record) => moment(record.validity.from).format("Do MMM"),
    },
    {
      title: "End Date",
      dataIndex: "t",
      key: "end",
      render: (t, record) => moment(record.validity.till).format("Do MMM"),
    },
    {
      title: "Price",
      dataIndex: "total_cost",
      key: "price",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let statusName =
          status === 1 ? "pending" : status === 2 ? "active" : "completed";
        return <span className={`status ${statusName}`}>{statusName}</span>;
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (record) => (
    //     <IconButton aria-label="more" size="small">
    //       <MoreHorizOutlinedIcon />
    //     </IconButton>
    //   ),
    // },
  ];

  const [listingModal, setListingModal] = useState(false);
  const [promoteModal, setPromoteModal] = useState(false);

  return (
    <div id="dashboard">
      <div className="overview">
        <div className="col-left">
          <h4>
            Welcome back, <span>{overviewData?.first_name}</span>
          </h4>
          <div className="line-2">
            <div>
              <h5>Company Name:</h5>
              <p>{overviewData?.company_name}</p>
            </div>
            <div>
              <h5>Email Address:</h5>
              <p>{overviewData?.email}</p>
            </div>
          </div>
        </div>
        <div className="col-right">
          <Link to="/profile">
            <Button
              variant="outlined"
              startIcon={<img src="/assets/icons/edit-grey.svg" />}
            >
              Edit Profile
            </Button>
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6">
          <div className="promotion-card">
            <div className="promotion-header">
              <h3 className="promotion-title">My Listing</h3>
              <Button variant="contained" onClick={() => setListingModal(true)}>
                <img src="/assets/icons/edit.svg" alt="edit" />
                Edit
              </Button>
            </div>
            <div className="promotion-body">
              <img className="user-logo" src={listingData?.img ? (baseUrl + listingData?.img) : ""} />
              <h3>{listingData?.company_name}</h3>
              <h5>{listingData?.website}</h5>
              {
                listingData?.is_approved ? (
                  <span className="status promo-approved">Approved</span>
                  ) : (
                    <span className="status promo-pending">Pending</span>
                  )
              }
              <Button
                className="main-button"
                variant="contained"
                onClick={() => setPromoteModal(true)}
              >
                <img src="/assets/icons/promote.svg" alt="promote" />
                Promote Now
              </Button>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 col-md-6">
          <div className="promotion-table">
            <h3 className="title"> My Promotions </h3>
            <Table
              dataSource={promotionData}
              columns={columns}
              pagination={false}
            />
          </div>
          ;
        </div>
      </div>

      <Modal
        title="Edit Listings"
        className="editLisitingModal"
        open={listingModal}
        onCancel={() => setListingModal(false)}
        centered
        footer={null}
      >
        <EditListing baseUrl={baseUrl} data={listingData} getListingData={getLisitingData} setListingModal={setListingModal} />
      </Modal>

      <Modal
        title="Promote Now"
        className="promoteNowModal"
        open={promoteModal}
        onCancel={() => setPromoteModal(false)}
        centered
        footer={null}
      >
        <PromoteForm plans={promotionPlans} />
      </Modal>
    </div>
  );
};

export default Dashboard;
