import React, { useEffect, useState } from "react";
import "./Profile.css";
import { notification } from "antd";
import ProfileForm from "./ProfileForm";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import { ImageUploader } from "../../Services/Utils/Utils";
import { changeImage, uploadImage } from "../../Services/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../Services/Store/userSlice";

const Profile = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageResponse, setImageResponse] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [companyName, setCompanyName] = useState('')

  const userDetails = useSelector((state) => state.user.value)
  const userBaseUrl = useSelector((state) => state.user.baseUrl);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState("edit-profile");
  
  useEffect(() => {
    if(userDetails) {
      setImageUrl(userBaseUrl + userDetails.img)
      setCompanyName(userDetails?.company_name)
    }
  }, [userDetails])
  
  // Handle Image Change
  const handleImageChange = async (fileObj, base64) => {
    setImageUrl(base64);
    // form.setFieldsValue({ image: fileObj });
    // Check if the API has already been called

    setApiCalled(true); // Set the state to true to indicate that the API is being called
    const result = await uploadImage(fileObj);
    const { data, status, error } = result || {};

    if (status === 200) {
      setImageResponse(data?.img);
      changeProfileImage(data?.img)
      dispatch(updateUserData({img: data?.img}))
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  const changeProfileImage = async (image) => {
    const result = await changeImage(image);
    const {status, data, error} = result

    if (status === 200) {
      notification.success({message: data?.message})
    } else {
      notification.error({ message: "Internal Server Error"})
    }
  }

  return (
    <div className="profile">
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-5">
          <div className="profile-card">
            <div className="image-uploader">
              <ImageUploader
                // form={form}
                imageUrl={imageUrl}
                handleChange={handleImageChange}
              />
            </div>
            <h3>{companyName}</h3>
            <div className="links">
              <Link
                to="#"
                className={currentTab === "edit-profile" ? "active" : ""}
                onClick={() => setCurrentTab("edit-profile")}
              >
                <img src="/assets/icons/edit-light.svg" alt="icon" />
                <img src="/assets/icons/edit-hover.svg" alt="icon" />
                Edit Profile
              </Link>
              <Link
                to="#"
                className={currentTab === "change-pass" ? "active" : ""}
                onClick={() => setCurrentTab("change-pass")}
              >
                <img src="/assets/icons/lock.svg" alt="icon" />
                <img src="/assets/icons/lock-hover.svg" alt="icon" />
                Change Password
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 col-md-7">
          <div className="profile-form">
            <h3 className="title">
              {currentTab === "edit-profile"
                ? "Edit Profile"
                : "Change Password"}
            </h3>
            <div className="profile-form-body">
              {currentTab === "edit-profile" ? (
                <ProfileForm />
              ) : (
                <ChangePassword />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;