import React, { useEffect, useState } from "react";
import { PageHeader } from "../../Components";
import "./ContactUs.css";
import { getContent, postContactUs } from "../../Services/Api/Api";
import { Col, Form, Input, Row, notification } from "antd";
import Button from "@mui/material/Button";
import { scrollTop } from "../../Services/Utils/Utils";

const ContactUs = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      address: "",
      emails: ["", ""],
      phones: ["", ""],
    },
  ]);
  const { address, emails, phones } = data || {};
  const [email_1, email_2] = emails || [];
  const [phone_1, phone_2] = phones || [];

  const getData = async () => {
    const result = await getContent("contact-us");
    const { data, status, error } = result;
    if (status === 200) {
      setData(data?.cms);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    scrollTop();
    getData();
  }, []);

  // Submit Contact Us Form Data
  const onFinish = async (values) => {
    const result = await postContactUs(values);
    const { data, status, error } = result || {};
    if (status === 200) {
      form.resetFields();
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: error?.message });
    }
  };

  return (
    <div className="contact-us">
      <PageHeader title="Contact Us" />
      <div className="inner-container">
        <div className="col-left">
          <h3>Have Questions? Get in Touch!</h3>
          <div class="contact-info">
            <div class="phone">
              <div className="icon">
                <img src="/assets/icons/phone.svg" />
              </div>
              <div className="text">
                {phone_1 && (
                  <div>
                    <a href={`tel:${phone_1}`}>Tel: {phone_1}</a>
                  </div>
                )}
                {phone_2 && (
                  <div>
                    <a href={`tel:${phone_2}`}>Phone: {phone_2}</a>
                  </div>
                )}
              </div>
            </div>
            <div class="email">
              <div className="icon">
                <img src="/assets/icons/mail.svg" />
              </div>
              <div className="text">
                {data?.emails?.map((email, index) => (
                  <div key={index}>
                    <a href={`mailto:${email}`}> {email}</a>
                  </div>
                ))}
              </div>
            </div>
            <div class="address">
              <div className="icon">
                <img src="/assets/icons/map.svg" />
              </div>
              <div className="text">{data?.address}</div>
            </div>
          </div>
        </div>
        <div className="col-right">
          <div className="contact-form">
            <Form form={form} name="basic" onFinish={onFinish}>
              <Row gutter={25}>
                {/* <Col span={12}>
                    <Form.Item
                      name="full_name"
                      rules={[
                        {
                          required: true,
                          message: "You cannot leave this field blank",
                        },
                        {
                          max: 100,
                          message: "Can not be more than 100 characters.",
                        },
                      ]}
                    >
                      <Input placeholder="Full Name" size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      type="email"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email",
                        },
                        {
                          required: true,
                          message: "You cannot leave this field blank",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email Address"
                        size="large"
                        type="email"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "You cannot leave this field blank.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Phone Number"
                        size="large"
                        type="number"
                      />
                    </Form.Item>
                  </Col> */}
                <Col span={24}>
                  <Form.Item
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: "You cannot leave this field blank.",
                      },
                    ]}
                  >
                    <Input placeholder="Subject" size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "You cannot leave this field blank",
                      },
                      { min: 10, message: "Should be 10 characters." },
                      {
                        max: 255,
                        message: "Can not be more than 255 characters.",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Your message here..."
                      size="large"
                      rows={5}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="form-contact-button">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className="main-button"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
