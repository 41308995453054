import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { getProfile } from "../../Services/Api/Api";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateBaseUrl, updateUserData } from "../../Services/Store/userSlice";

function Header() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");
  const path = location?.pathname;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.value)
  const userBaseUrl = useSelector((state) => state.user.baseUrl);

  const [baseUrl, setBaseUrl] = useState('')

  const [data, setData] = useState({});

  const getPath = () => {
    return path.slice(1).replace(/-/g, " ");
  };

  useEffect(() => {
    let covertedPath = getPath();
    setCurrentPage(covertedPath);
  }, [path]);

  const getData = async () => {
    if (userDetails) {
      setData(userDetails);
      setBaseUrl(userBaseUrl);
    } else {
      const result = await getProfile();
      const { data, status, error } = result;
      if (status === 200) {
        setData(data?.user);
        setBaseUrl(data?.base_url);
        dispatch(updateUserData(data?.user));
        dispatch(updateBaseUrl(data?.base_url));
      } else {
        notification.error({ message: error || "Internal server error" });
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="admin-header">
      <h2 className="title">
        Dashboard
        {currentPage && <span className="currentPage">{currentPage}</span>}
      </h2>
      <div className="head-account">
        <Link to='/profile'>
          <img src={data?.img ? (baseUrl + data?.img) : ""} alt="user-logo" />
        </Link>
      </div>
    </div>
  );
}

export default Header;
