import React, { useState } from "react";
import { DatePicker, Form, Input, Select, notification } from "antd";
import { Button } from "@mui/material";
import { ImageUploader } from "../../Services/Utils/Utils";
import { createPromotion, uploadImage } from "../../Services/Api/Api";
import moment from "moment";

const PromoteForm = ({plans}) => {
  const [form] = Form.useForm();
  const [promotionType, setPromotionType] = useState(0);
  const [startDate, setStartDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const [imageResponse, setImageresponse] = useState("");
  const [apiCalled, setApiCalled] = useState(false);

  // Handle Image Change
  const handleImageChange = async (fileObj, base64) => {
    setImageUrl(base64);
    // form.setFieldsValue({ image: fileObj });
    // Check if the API has already been called

    setApiCalled(true); // Set the state to true to indicate that the API is being called
    const result = await uploadImage(fileObj);
    const { data, status, error } = result || {};

    if (status === 200) {
      setImageresponse(data?.img);
      form.setFieldsValue({ image: data?.img });
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  const onFinish = async (values) => {
    const result = await createPromotion({ ...values, startDate });
    const { data, status, error } = result || {};
    if (status === 200) {
      form.resetFields();
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: error?.message });
    }
  };

  const onDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const onTypeChange = (value) => {
    const planItem = plans.find(item => item._id === value);
    const planTitle = planItem.title.toLowerCase()
    if (planItem && planTitle.includes('banner')) {
        setPromotionType(2);
    } else {
        setPromotionType(1);
    }
  };

  function disabledDate(current) {
    return current && current <= moment().endOf('day');
  }

  return (
    <div>
      <Form className="edit-profile-form" form={form} onFinish={onFinish}>
        <div className="start-date">
          <Form.Item
            name="startdate"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <DatePicker
              placeholder="Select Start Date"
              size="large"
              onChange={onDateChange}
              disabledDate={disabledDate}
              // allowClear={false}\
              showNow={false}
            />
          </Form.Item>
        </div>

        <div className="duration">
          <Form.Item
            name="duration"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Enter Duration (No of Days)"
              size="large"
            />
          </Form.Item>
        </div>

        <div className="type">
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <Select
              placeholder="Select Promotion Type"
              onChange={onTypeChange}
              popupClassName="promo-type-list"
              options={plans?.map((item, index) =>  ({
                key: index,
                value: item._id,
                label: item.type === 1 ? "Top of the List" : item.type === 2 ? "Banner Promotion" : null
              }))}
            />
          </Form.Item>
        </div>

        {promotionType === 2 && (
          <div className="image">
            <Form.Item
              name="image"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <div className="image-uploader">
                <ImageUploader
                  form={form}
                  imageUrl={imageUrl}
                  handleChange={handleImageChange}
                />
              </div>
            </Form.Item>
          </div>
        )}

        <div className="actions">
          <Button type="submit" variant="contained" className="main-button">
            Promote
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PromoteForm;