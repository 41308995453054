import React, { useState } from "react";
import { LockOutlined } from "@ant-design/icons";

import { Form, Input, notification } from "antd";
import { Button } from "@mui/material";
import { changePassword } from "../../Services/Api/Api";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await changePassword(values);

    const { status, data, error } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(false);
      notification.error({ message: error || "Internal Server Error" });
    }
  };

  const validatePassword = (_, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("new_password")) {
      return Promise.reject("Passwords do not match");
    }
    return Promise.resolve();
  };

  return (
    <div>
      <Form
        className="edit-profile-form"
        form={form}
        onFinish={onFinish}
      >
        <div className="password">
          <Form.Item
            name="curr_password"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              { min: 8, message: "Should be 8 characters." },
              {
                max: 100,
                message: "Can not be more than 100 characters.",
              },
            ]}
          >
            <Input.Password
              prefix={<img src="/assets/icons/lock-pass.svg" className="site-form-item-icon" />}
              placeholder="Current Password"
              size="large"
            />
          </Form.Item>
        </div>

        <div className="password">
          <Form.Item
            name="new_password"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              { min: 8, message: "Should be 8 characters." },
              {
                max: 100,
                message: "Can not be more than 100 characters.",
              },
            ]}
          >
            <Input.Password
              prefix={<img src="/assets/icons/lock-pass.svg" className="site-form-item-icon" />}
              placeholder="New Password"
              size="large"
            />
          </Form.Item>
        </div>

        <div className="confirm-password">
          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              {
                max: 100,
                message: "Can not be more than 100 characters.",
              },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              prefix={<img src="/assets/icons/lock-pass.svg" className="site-form-item-icon" />}
              placeholder="Confirm New Password"
              size="large"
            />
          </Form.Item>
        </div>

        <div className="actions">
          <Button type="submit" variant="contained" className="main-button">
            Update Password
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;