import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";
import { Form, Input, notification } from "antd";
import { forgetPassword } from "../../Services/Api/Api";
import { validateEmail } from "../../Services/Utils/Utils";

const ForgotPass = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const trimmedEmail = values.email.trim()
    const formValues = {
      ...values,
      email: trimmedEmail
    }
    const result = await forgetPassword(formValues);
    const { data, error, status } = result || {};

    if (status === 200) {
      notification.success({ message: data?.message });
      form.resetFields()
    } else {
      notification.error({ message: error?.message });
    }
  };

  return (
    <div className="auth-form-wrapper">
      <h2 className="title"> Reset Your Password</h2>
      <h4 className="sub-title">
        Enter the email address associated with your account below.
      </h4>

      <Form form={form} className="forgot-form" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              validator: validateEmail
            },
            {
              required: true,
              message: "You cannot leave this field blank",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email Address"
            size="large"
          />
        </Form.Item>

        <div className="button-group">
          <Button type="submit" variant="contained" className="main-button">
            Send
          </Button>
          <Link to='/admin/login' className="cancel"> <Button variant="outlined"> Cancel </Button> </Link>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPass;