import { Button } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, notification } from "antd";
import { loginUser } from "../../Services/Api/Api";
import { validateEmail } from "../../Services/Utils/Utils";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const trimmedEmail = values.email.trim()
    const formValues = {
      ...values,
      email: trimmedEmail
    }
    const result = await loginUser(formValues);
    const { data, status, error } = result || {};

    if (status === 201) {
      localStorage.setItem("token", data?.access_token);
      notification.success({ message: data?.message || "Login Successfull" });
      navigate("/");
    } else {
      notification.error({
        message: error?.message || "Internal Server Error",
      });
    }
  };

  return (
    <div className="auth-form-wrapper">
      <h2 className="title"> Welcome to The Gallery </h2>
      <h4 className="sub-title"> Log in to your account. </h4>

      <Form form={form} className="login-form" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              validator: validateEmail
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email Address"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "You cannot leave this field blank",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Link to="/forgot" className="login-form-forgot">
            Forgot Password?
          </Link>
        </Form.Item>
        <Button type="submit" variant="contained" className="main-button">
          Login
        </Button>
      </Form>
      <p className="register-msg">
        Don't have an account? <Link to="/register">Register</Link>
      </p>
    </div>
  );
};

export default Login;