import { apiHeader, apiNoHeader } from "./BaseUrl";

//Register API
export const registerUser = async (values) => {
  let data;
  await apiHeader
    .post("/user/register", {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      company_name: values.company,
      website: values.website,
      img: values.image
    })
    .then((res) => {
      data = {
        status: true,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: false,
        };
      }
    });
  return data;
};

//Login API
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      // device_type: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Login API
export const logoutUser = async () => {
  let data;
  const refresh_token = localStorage.getItem("token")
  await apiHeader
    .post("/auth/logout", {
      "refresh_token": refresh_token,
      "device_token": "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Refresh Token Api
export const refreshAccessToken = async (refreshToken) => {
let data;
await apiNoHeader
  .post("/auth/token/refresh", {
    refresh_token: refreshToken,
  })
  .then((res) => {
    data = {
      status: res.status,
      data: res.data,
    };
  })
  .catch((e) => {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  });
return data;
};

// Auth - Forgot Password
export const forgetPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  let data;
  await apiHeader
  .post("/auth/forgotpwd/reset", {
    email: values.email,
    forgot_pwd_token: token,
    new_password: values.newpassword
  })
  .then((res) => {
    data = {
      status: res.status,
      data: res.data,
    };
  })
  .catch((e) => {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  });
  return data;
};

// Upload Image
export const uploadImage = async (image) => {
  let data;
  const apiUrl = "/img/upload";
  const formData = new FormData();
  formData.append("img", image);
  try {
    const res = await apiNoHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }
  return data;
};

// Get Profile
export const getProfile = async () => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Update Profile
export const updateProfile = async (values) => {
  let data;
  await apiHeader
    .put("/user/profile", {
      "first_name": values?.firstname,
      "company_name": values?.company,
      "email": values?.email
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Change Image
export const changeImage = async (image) => {
  let data;
  await apiHeader
    .patch("/user/change/img", {
      "img": image
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Change Password
export const changePassword = async (values) => {
  let data;
  await apiHeader
    .patch("/user/password/reset", {
      "current_password": values.curr_password,
      "new_password": values.new_password,
      "confirm_password": values.confirm_password
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Get Promotions
export const getPromotions = async () => {
  let data;
  await apiHeader
    .get("/promotions/vendor")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Get Promotions
export const getPromotionsPlans = async () => {
  let data;
  await apiHeader
    .get("/promotions")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Update Business
export const createPromotion = async (values) => {
  let data;
  await apiHeader
    .post("/promotions/vendor", {
      "start_date": values.startDate,
      "duration": values.duration,
      "promotion_id": values.type,
      "img": values?.image,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Get Business
export const getBusiness = async () => {
  let data;
  await apiHeader
    .get("/business")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};



// Update Business
export const updateBusiness = async (values) => {
  let data;
  await apiHeader
    .put("/business", {
      "img": values.image,
      "description": values.details,
      "company_name": values.company,
      "website_link": values.web
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Get Content
export const getContent = async (type) => {
  let data;
  await apiHeader
    .get(`/content/${type}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Contact Us
export const postContactUs = async (values) => {
  let data;
  await apiHeader
    .post("/content/contact-us", {
      "subject": values.subject,
      "message": values.message
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
