import React from "react";
import "./AuthLayout.css";
import { Link, Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <div className="auth-layout">
      <div className="auth-container">
        <div className="left">
          <Link to="/" className="logo">
            <img src="/assets/logo.png" alt="image" />
          </Link>
        </div>
        <div className="right">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
