import React, { useEffect, useState } from "react";
import { PageHeader } from "../../Components";
import "./TermsCondition.css";
import { getContent } from "../../Services/Api/Api";
import { notification } from "antd";

const TermsCondition = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const result = await getContent("terms-n-condition");
    const { data, status, error } = result;

    if (status === 200) {
      setData(data?.cms);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="about-us">
      <PageHeader title="Terms & Conditons" />
      <div className="inner-container">
        {data?.filter((item) => item.active)
          .map((item, index) => (
            <div key={index}>
              <h3 className="heading"> {item?.title} </h3>
              <div className="border"></div>
              <p className="desc"> {item?.description} </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TermsCondition;