import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
  baseUrl: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      };
    },
    updateBaseUrl: (state, action) => {
      state.baseUrl = action.payload;
    },
    clearUserData: (state) => {
      state.value = null
      state.baseUrl = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateUserData, updateBaseUrl, clearUserData } = userSlice.actions

export default userSlice.reducer