import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, notification } from "antd";
import { resetPassword } from "../../Services/Api/Api";
import { validateEmail } from "../../Services/Utils/Utils";

const ResetPass = () => {
  let { token } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!token) {
      navigate('/forgot')
    }
  }, [])
  
  const onFinish = async (values) => {
    const trimmedEmail = values.email.trim()
    const formValues = {
      ...values,
      email: trimmedEmail
    }
    const result = await resetPassword(formValues, token);
    const { data, error, status } = result;
    if (status === 200) {
      notification.success({ message: data?.message });
      setTimeout(() => {
        navigate("/login");
      }, 500);
    } else {
      notification.error({ message: error?.message });
    }
  };

  const validatePassword = (_, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("newpassword")) {
      return Promise.reject("Passwords do not match");
    }
    return Promise.resolve();
  };

  return (
    <div className="auth-form-wrapper">
      <h2 className="title"> Reset Your Password</h2>
      <h4 className="sub-title">
        Enter the email address along with your new password.
      </h4>

      <Form form={form} className="forgot-form" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              validator: validateEmail
            },
            {
              required: true,
              message: "You cannot leave this field blank",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Enter Email"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="newpassword"
          rules={[
            {
              required: true,
              message: "You cannot leave this field blank",
            },
            { min: 8, message: "Should be 8 characters." },
            {
              max: 50,
              message: "Can not be more than 50 characters.",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Enter New Password"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="confirmpassword"
          rules={[
            {
              required: true,
              message: "You cannot leave this field blank",
            },
            { min: 8, message: "Should be 8 characters." },
            {
              max: 30,
              message: "Can not be more than 30 characters.",
            },
            {
              validator: validatePassword, // Use the custom validator function
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Enter Confirm Password"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Button type="submit" variant="contained" className="main-button">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPass;
