import React, { useState } from "react";
import {
  LockOutlined,
  UserOutlined,
  GlobalOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Form, Input, notification } from "antd";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { validatePassword, ImageUploader } from "../../Services/Utils/Utils";

import { registerUser, uploadImage } from "../../Services/Api/Api";

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const [imageResponse, setImageresponse] = useState("");
  const [apiCalled, setApiCalled] = useState(false);

  // Handle Image Change
  const handleImageChange = async (fileObj, base64) => {
    setImageUrl(base64);
    // form.setFieldsValue({ image: fileObj });
    // Check if the API has already been called

    setApiCalled(true); // Set the state to true to indicate that the API is being called
    const result = await uploadImage(fileObj);
    const { data, status, error } = result || {};

    if (status === 200) {
      setImageresponse(data?.img);
      form.setFieldsValue({ image: data?.img });
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);

    const result = await registerUser(values);
    const { data, status, error } = result;
    if (status) {
      notification.success({ message: data?.message });
      setTimeout(() => {
        setLoading(false);
        navigate("/login");
      }, 500);
    } else {
      setLoading(false);
      notification.error({ message: error || "Internal Server Error" });
    }
  };

  return (
    <div className="auth-form-wrapper">
      <h2 className="title"> Welcome to The Gallery </h2>
      <h4 className="sub-title"> Register to continue. </h4>

      <Form
        className="register-form"
        form={form}
        onFinish={onFinish}
      >
        <div className="image-wrapper">
          <Form.Item
            name="image"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <ImageUploader
              form={form}
              imageUrl={imageUrl}
              handleChange={handleImageChange}
            />
            <span className="label">Upload Logo</span>
          </Form.Item>
        </div>
        <div className="res-wrap">
          <div className="firstname">
            <Form.Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                { min: 3, message: "Should be 3 characters." },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="First Name"
                size="large"
              />
            </Form.Item>
          </div>

          <div className="lastname">
            <Form.Item
              name="lastname"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                { min: 3, message: "Should be 3 characters." },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Last Name"
                size="large"
              />
            </Form.Item>
          </div>
        </div>

        <div className="company">
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              {
                max: 100,
                message: "Can not be more than 100 characters.",
              },
            ]}
          >
            <Input
              placeholder="Company Name"
              size="large"
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </div>

        <div className="website">
          <Form.Item
            name="website"
            rules={[
              {
                type: "url",
                message: "Invalid Url",
              },
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <Input
              placeholder="Website"
              size="large"
              prefix={<GlobalOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </div>

        <div className="email">
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Invalid email",
              },
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email Address"
              size="large"
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </div>

        <div className="res-wrap d-flex justify-content-between">
          <div className="password">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                { min: 8, message: "Should be 8 characters." },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                size="large"
              />
            </Form.Item>
          </div>

          <div className="confirm-password">
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
                {
                  validator: validatePassword(form),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Confirm"
                size="large"
              />
            </Form.Item>
          </div>
        </div>

        <div className="register-button">
          <Button type="submit" variant="contained" className="main-button">
            Register
          </Button>
        </div>
      </Form>
      <p className="register-msg">
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </div>
  );
};

export default Register;