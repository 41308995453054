import React, { useEffect, useState } from "react";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Form, Input, notification } from "antd";
import { Button } from "@mui/material";

import { updateProfile } from "../../Services/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../Services/Store/userSlice";
import { validateName } from "../../Services/Utils/Utils";

const ProfileForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.value);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await updateProfile(values);

    const { status, data, error } = result;
    if (status === 200) {
      notification.success({ message: data?.message });
      dispatch(
        updateUserData({
          first_name: values?.firstname,
          company_name: values?.company,
          email: values?.email,
        })
      );
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(false);
      notification.error({ message: error });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstname: userDetails?.first_name,
      company: userDetails?.company_name,
      email: userDetails?.email,
    });
  }, []);

  return (
    <div>
      <Form className="edit-profile-form" form={form} onFinish={onFinish}>
        <div className="firstname">
          <Form.Item name="firstname" rules={[{ validator: validateName }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="First Name"
              size="large"
            />
          </Form.Item>
        </div>

        <div className="company">
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "You cannot leave this field blank",
              },
              {
                max: 30,
                message: "Can not be more than 30 characters.",
              },
            ]}
          >
            <Input
              placeholder="Company Name"
              size="large"
              prefix={
                <img
                  src="/assets/icons/work.svg"
                  className="site-form-item-icon"
                />
              }
            />
          </Form.Item>
        </div>

        <div className="email">
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Invalid email",
              },
              {
                required: true,
                message: "You cannot leave this field blank",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email Address"
              size="large"
              prefix={
                <img
                  src="/assets/icons/mail-grey.svg"
                  className="site-form-item-icon"
                />
              }
              disabled
            />
          </Form.Item>
        </div>

        <div className="actions">
          <Button type="submit" variant="contained" className="main-button">
            Save Changes
          </Button>
          <Link onClick={() => form.resetFields()}>Discard Changes</Link>
        </div>
      </Form>
    </div>
  );
};

export default ProfileForm;
