import axios from "axios";
// import { refreshAccessToken } from "./Api";
import { clearLocalStorage } from "../Utils/Utils";

export const apiHeader = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});
export const apiNoHeader = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (subscriber) => {
  refreshSubscribers.push(subscriber);
};

const onRefreshed = (token) => {
  refreshSubscribers.map((subscriber) => subscriber(token));
};

// const handleTokenRefresh = async () => {
//   if (!isRefreshing) {
//     isRefreshing = true;
//     try {
//       const refreshToken = localStorage.getItem("refresh_token");
//       const response = await refreshAccessToken(refreshToken);
//       const newToken = response?.data?.access_token;
//       const newRefreshToken = response?.data?.refresh_token;

//       if (newToken) {
//         localStorage.setItem("token", newToken);
//         localStorage.setItem("refresh_token", newRefreshToken);
//         onRefreshed(newToken);
//         window.location.reload()
//       }
//       else if(response?.error?.en === "Token does not exist"){
//         clearLocalStorage()
//         window.location.href = '/login';
//       }
//     } catch (error) {
//       console.error("Error refreshing access token:", error);
//       clearLocalStorage();
//       window.location.href = "/login";
//     } finally {
//       isRefreshing = false;
//       refreshSubscribers = [];
//     }
//   }
// };

apiHeader.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Accept-Language"] = "en";
    if (config.url === '/user/profile-image') {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-localization"] = "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// apiHeader.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401) {
//       if (
//         error?.response?.data?.message === "Jwt Token Expired" ||
//         error?.response?.data?.message === "Invalid Token"
//       ) {
//         if (!isRefreshing) {
//           handleTokenRefresh();
//           return new Promise((resolve) => {
//             subscribeTokenRefresh((token) => {
//               originalRequest.headers.Authorization = `Bearer ${token}`;
//               resolve(axios(originalRequest));
//             });
//           });
//         } else {
//           return new Promise((resolve) => {
//             subscribeTokenRefresh((token) => {
//               originalRequest.headers.Authorization = `Bearer ${token}`;
//               resolve(axios(originalRequest));
//             });
//           });
//         }
//       } else if (error?.response?.data?.message === "Account Deactivated") {
//         clearLocalStorage();
//         window.location.href = "/login";
//       }
//     }
//     return Promise.reject(error);
//   }
// );
