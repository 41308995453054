import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./AdminLayout.css";
import { Header } from "../Components";
import { useScreenWidth } from "../Services/Utils/Utils";
import MenuIcon from "@mui/icons-material/Menu";
import { logoutUser } from "../Services/Api/Api";
import { useDispatch } from "react-redux";
import { clearUserData } from "../Services/Store/userSlice";

function AdminLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useScreenWidth();
  const path = location?.pathname;
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    const result = await logoutUser();
    if (result?.status === 200) {
      localStorage.clear();
      dispatch(clearUserData());
      navigate("/login");
    }
  };

  return (
    <div className="admin-layout">
      <div className={openSidebar ? "sidebar col-left" : "col-left"}>
        <div className="left">
          {screenWidth >= 500 ? (
            <div className="admin-logo d-flex align-items-center">
              <Link to="/" className="logo">
                <img src="/assets/logo.png" alt="image" />
              </Link>
              <MenuIcon
                onClick={() => {
                  setOpenSidebar(!openSidebar);
                }}
              />
            </div>
          ) : (
            <div className="mt-4" />
          )}
          {/* Navigation for admin section */}
          <ul>
            <li className={path == "/" ? "active" : null}>
              <Link to="/">
                <img
                  src="/assets/icons/dashboard-hover.svg"
                  alt="image"
                  className="hover-image"
                />
                <img src="/assets/icons/dashboard.svg" alt="image" />
                <p>Dashboard</p>
              </Link>
            </li>

            <li className={path == "/profile" ? "active" : null}>
              <Link to="/profile">
                <img
                  src="/assets/icons/my_profile-hover.svg"
                  alt="image"
                  className="hover-image"
                />
                <img src="/assets/icons/my_profile.svg" alt="image" />
                <p>Profile</p>
              </Link>
            </li>

            <li
              className={
                path == "/about-us"
                  ? "active"
                  : path == "/admin/market/primary-market"
                  ? "active"
                  : null
              }
            >
              <Link to="/about-us">
                <img
                  src="/assets/icons/about_us-hover.svg"
                  alt="image"
                  className="hover-image"
                />
                <img src="/assets/icons/about_us.svg" alt="image" />
                <p>About Us</p>
              </Link>
            </li>

            <li className={path == "/contact-us" ? "active" : null}>
              <Link to="/contact-us">
                <img
                  src="/assets/icons/contact_us-hover.svg"
                  alt="image"
                  className="hover-image"
                />
                <img src="/assets/icons/contact_us.svg" alt="image" />
                <p>Contact Us</p>
              </Link>
            </li>

            <li>
              <Link to="#" onClick={logoutHandler}>
                <img
                  src="/assets/icons/logout-hover.svg"
                  alt="image"
                  className="hover-image"
                />
                <img src="/assets/icons/logout.svg" alt="image" />
                <p>Logout</p>
              </Link>
            </li>
          </ul>
          <p className="cpy">Copyright © 2023 The Gallery.</p>
        </div>
      </div>

      <div
        className={
          openSidebar || screenWidth <= 500 ? "sidebar col-right" : "col-right"
        }
      >
        <div className="right">
          <Header />
          {/* Outlet to show admin pages */}
          <div className="admin-container">
            <Outlet />
          </div>
          <div className="admin-footer">
            <div className="links">
              <Link to="/terms-conditon"> Terms & Conditions </Link>|
              <Link to="/privacy-policy"> Privacy Policy </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;