import React, { useEffect, useState } from "react";
import { PageHeader } from "../../Components";
import "./PrivacyPolicy.css";
import { getContent } from "../../Services/Api/Api";
import { notification } from "antd";

const PrivacyPolicy = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const result = await getContent("privacy-policy");
    const { data, status, error } = result;

    console.log(result);
    if (status === 200) {
      setData(data?.cms);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="about-us">
      <PageHeader title="Privacy Policy" />
      <div className="inner-container">
        {data?.map((item, index) => (
          <div key={index}>
            <h3 className="heading"> {item?.title} </h3>
            <div className="border"></div>
            <p className="desc"> {item?.description} </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
