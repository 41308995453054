import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "../Pages/AdminLayout";
import AuthLayout from "../Pages/Auth/AuthLayout";
import {
  AboutUs,
  ContactUs,
  Dashboard,
  ForgotPass,
  Login,
  PrivacyPolicy,
  Profile,
  Register,
  ResetPass,
  TermsCondition,
} from "../Pages";
import { PublicRoutes } from "./PublicRoutes";
import { ProtectedRoutes } from "./ProtectedRoutes";

// Authentication (If Login or Not)
const Auth = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/*  Public Routes  */}
        <Route element={<PublicRoutes Auth={Auth} />}>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot" element={<ForgotPass />} />
            <Route path="/reset-password/:token" element={<ResetPass />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Route>

        {/*  Protected Routes */}
        <Route element={<ProtectedRoutes Auth={Auth} />}>
          <Route path="/" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditon" element={<TermsCondition />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
